import React from "react";

import Navbar2 from "./Inicio/navbar2";
import Footer from "./Inicio/footer";
import Sitiopage from "./Sitios/sitio-pg1";

function Sitiopg1() {
    return (<div>
        <Navbar2 />
        <Sitiopage/>
        <Footer />
    </div>
    )
}

export default Sitiopg1;