import React from "react";
import './parcerias.css';

function Parcerias() {

    return (
        <section id="parceria">
            <div class="container-fluid right-position">
                <div class="row gradient-bg">
                    <div class="col-lg-12">
                        <div class="parceria-text section-header text-center">
                            <div>
                                <h2 class="section-title">Parcerias do Negócio</h2>
                                <div class="desc-text">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center parceria-area">
                    <div class="col-lg-12 col-md-12 col-xs-12 pr-0">
                        <div class="parceria-slider owl-carousel">
                            <div class="item">
                                <div class="screenshot-thumb">
                                    <img src="../../../img/img-parceria.png" class="img-fluid" alt />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Parcerias;