import React, { useState } from "react";
import { Link } from "react-router-dom";
import './fazenda-pg1.css';

const ImageModal = ({ onClose }) => {

    return (
        <div className="script-modal over">
            <div className="script-modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <br /><br />
                <div>
                    <img src="../../../img/img-imovel1.jpg" alt="" />
                </div>
            </div>
        </div>

    );
}
const ImageModal01 = ({ onClose }) => {

    return (
        <div className="script-modal over">
            <div className="script-modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <br /><br />
                <div>
                <img src="../../../img/img-imovel2.jpg" alt="" />

                </div>
            </div>
        </div>

    );
}
const ImageModal02 = ({ onClose }) => {

    return (
        <div className="script-modal over">
            <div className="script-modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <br /><br />
                <div>
                    <img src="../../../img/img-imovel3.jpg" alt="" />
                </div>
            </div>
        </div>

    );
}
const ImageModal03 = ({ onClose }) => {

    return (
        <div className="script-modal over">
            <div className="script-modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <br /><br />
                <div>
                    <img src="../../../img/img-imovel4.jpg" alt="" />
                </div>
            </div>
        </div>

    );
}
const ImageModal04 = ({ onClose }) => {

    return (
        <div className="script-modal over">
            <div className="script-modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <br /><br />
                <div>
                    <img src="../../../img/img-imovel5.jpg" alt="" />
                </div>
            </div>
        </div>

    );
}


function Fazendapg1() {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const [visibleModal, setVisibleModal] = useState(false)

    const abrirModal = () => {
        setVisibleModal(true)
    }
    const fecharModal = () => {
        setVisibleModal(false)
    }
    const [visibleModal01, setVisibleModal01] = useState(false)

    const abrirModal01 = () => {
        setVisibleModal01(true)
    }
    const fecharModal01 = () => {
        setVisibleModal01(false)
    }


    const [visibleModal02, setVisibleModal02] = useState(false)

    const abrirModal02 = () => {
        setVisibleModal02(true)
    }
    const fecharModal02 = () => {
        setVisibleModal02(false)
    }


    const [visibleModal03, setVisibleModal03] = useState(false)

    const abrirModal03 = () => {
        setVisibleModal03(true)
    }
    const fecharModal03 = () => {
        setVisibleModal03(false)
    }


    const [visibleModal04, setVisibleModal04] = useState(false)

    const abrirModal04 = () => {
        setVisibleModal04(true)
    }
    const fecharModal04 = () => {
        setVisibleModal04(false)
    }




    return (
        <section>
            <div className="row box-item desc-imovel">

                {visibleModal && (
                    <ImageModal onClose = {fecharModal}/>
                )}
                <div className="item-img col-md-3">
                    <Link onClick={abrirModal}><img src="../../../img/img-imovel1.jpg" alt="" /></Link>
                </div>

                <div className="text-imovel col-md-9">
                    <div className="price-imovel">
                        <h4>R$ 45.000.000</h4>
                        <hr />
                    </div>
                    <div className="local-imovel">
                        <p className="font-weight-bold">MACAÉ</p>
                        <p>RJ, Centro, Macaé</p>
                    </div>
                    <div className="info-imovel">
                        <p>7108 m² tot. | 
7108 m² útil | 
8 banheiros | 
8 vaga | 
90 quarto | 
10 suítes | 
Breve Lançamento</p>
                        <p>Hotel a venda na cidade de Macaé - Região dos Lagos do Rio de Janeiro - RJ . <Link to={'https://www.imovelweb.com.br/propriedades/hotel-a-venda-em-macae-regiao-dos-lagos-rj-2979790746.html'}>...</Link></p>
                    </div>
                    <div className="icon-parceria">
                        <Link to={'https://www.imovelweb.com.br/'}><img src="../../../img/img-parceria.png" alt="" /></Link>
                        <div className="box-btn">
                            <Link className="btn btn-item btn-primary" to={'tel:21998513964'}><i class="fa fa-phone"></i></Link>
                            <Link className="btn btn-item btn-success" to={'https://wa.link/onasir'}><i class="fa fa-whatsapp"></i></Link>
                            <Link className="btn btn-item btn-danger" to={'https://www.imovelweb.com.br/propriedades/hotel-a-venda-em-macae-regiao-dos-lagos-rj-2979790746.html'}>Ver sobre <i class="fa fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row box-item desc-imovel">
            {visibleModal01 && (
                    <ImageModal01 onClose = {fecharModal01}/>
                )}
                <div className="item-img col-md-3">
                    <Link onClick={abrirModal01}><img src="../../../img/img-imovel2.jpg" alt="" /></Link>
                </div>
                <div className="text-imovel col-md-9">
                    <div className="price-imovel">
                        <h4>R$ 650.000</h4>
                        <hr />
                    </div>
                    <div className="local-imovel">
                        <p className="font-weight-bold">CACHOEIRAS DE MACACU</p>
                        <p>RJ , Zona Rural, Cachoeiras de Macacu</p>
                    </div>
                    <div className="info-imovel">
                        <p>80000 m² tot. | 
80000 m² útil | 
2 banheiros | 
5 vaga | 
2 quarto | 
1 suíte | 
Breve Lançamento</p>
                        <p>Excelente propriedade medindo 80.000m², composta de uma casa boa de dois quartos sendo uma suíte, sala enorme e dois ambientes<Link to={'https://www.imovelweb.com.br/propriedades/sitio-propriedade-em-cachoeiras-de-macacu-rj-116-2995073641.html'}>...</Link></p>
                    </div>
                    <div className="icon-parceria">
                        <Link to={'https://www.imovelweb.com.br/'}><img src="../../../img/img-parceria.png" alt="" /></Link>
                        <div className="box-btn">
                            <Link className="btn btn-item btn-primary" to={'tel:21998513964'}><i class="fa fa-phone"></i></Link>
                            <Link className="btn btn-item btn-success" to={'https://wa.link/onasir'}><i class="fa fa-whatsapp"></i></Link>
                            <Link className="btn btn-item btn-danger" to={'https://www.imovelweb.com.br/propriedades/sitio-propriedade-em-cachoeiras-de-macacu-rj-116-2995073641.html'}>Ver sobre <i class="fa fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row box-item desc-imovel">
            {visibleModal02 && (
                    <ImageModal02 onClose = {fecharModal02}/>
                )}
                <div className="item-img col-md-3">
                    <Link onClick={abrirModal02}><img src="../../../img/img-imovel3.jpg" alt="" /></Link>
                </div>
                <div className="text-imovel col-md-9">
                    <div className="price-imovel">
                        <h4>R$ 1.100.000</h4>
                        <hr />
                    </div>
                    <div className="local-imovel">
                        <p className="font-weight-bold">TERESÓPOLIS</p>
                        <p>Região Serrana do RJ , Água Quente, Teresópolis</p>
                    </div>
                    <div className="info-imovel">
                        <p>230000 m² tot. | 
230000 m² útil | 
2 banheiros | 
9 vaga | 
2 quarto | 
Breve Lançamento</p>
                        <p>Terra de 1° qualidade , 700 pés de tangerina pocam , plantados 2 anos, Clima suíço!! há 4 km do asfalto e o melhor aceitando parte em<Link to={'https://www.imovelweb.com.br/propriedades/fazendinha-em-teresopolis-medindo-230.000m-2994182644.html'}>...</Link></p>
                    </div>
                    <div className="icon-parceria">
                        <Link to={'https://www.imovelweb.com.br/'}><img src="../../../img/img-parceria.png" alt="" /></Link>
                        <div className="box-btn">
                            <Link className="btn btn-item btn-primary" to={'tel:21998513964'}><i class="fa fa-phone"></i></Link>
                            <Link className="btn btn-item btn-success" to={'https://wa.link/onasir'}><i class="fa fa-whatsapp"></i></Link>
                            <Link className="btn btn-item btn-danger" to={'https://www.imovelweb.com.br/propriedades/fazendinha-em-teresopolis-medindo-230.000m-2994182644.html'}>Ver sobre <i class="fa fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row box-item desc-imovel">
            {visibleModal03 && (
                    <ImageModal03 onClose = {fecharModal03}/>
                )}
                <div className="item-img col-md-3">
                    <Link onClick={abrirModal03}><img src="../../../img/img-imovel4.jpg" alt="" /></Link>
                </div>
                <div className="text-imovel col-md-9">
                    <div className="price-imovel">
                        <h4>R$ 3.500.000</h4>
                        <hr />
                    </div>
                    <div className="local-imovel">
                        <p className="font-weight-bold">CACHOEIRAS DE MACACU</p>
                        <p>RJ , Zona Rural, Cachoeiras de Macacu</p>
                    </div>
                    <div className="info-imovel">
                        <p>2 banheiros | 
9 vaga | 
3 quarto | 
Breve Lançamento</p>
                        <p>Linda propriedade em Cachoeiras de Macacu - RJ composta de 22 alqueires, com estrutura boa com curral , estábulo para <Link to={'https://www.imovelweb.com.br/propriedades/fazenda-em-cachoeiras-de-macacu-rj-2980897173.html'}>...</Link></p>
                    </div>
                    <div className="icon-parceria">
                        <Link to={'https://www.imovelweb.com.br/'}><img src="../../../img/img-parceria.png" alt="" /></Link>
                        <div className="box-btn">
                            <Link className="btn btn-item btn-primary" to={'tel:21998513964'}><i class="fa fa-phone"></i></Link>
                            <Link className="btn btn-item btn-success" to={'https://wa.link/onasir'}><i class="fa fa-whatsapp"></i></Link>
                            <Link className="btn btn-item btn-danger" to={'https://www.imovelweb.com.br/propriedades/fazenda-em-cachoeiras-de-macacu-rj-2980897173.html'}>Ver sobre <i class="fa fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row box-item desc-imovel">
            {visibleModal04 && (
                    <ImageModal04 onClose = {fecharModal04}/>
                )}
                <div className="item-img col-md-3">
                    <Link onClick={abrirModal04}><img src="../../../img/img-imovel5.jpg" alt="" /></Link>
                </div>
                <div className="text-imovel col-md-9">
                    <div className="price-imovel">
                        <h4>R$ 1.250.000</h4>
                        <hr />
                    </div>
                    <div className="local-imovel">
                        <p className="font-weight-bold">PETRÓPOLIS</p>
                        <p>Serra do Aviário , Xerém, Duque de Caxias</p>
                    </div>
                    <div className="info-imovel">
                        <p>12485836 m² tot. | 
12485836 m² útil | 
3 banheiros | 
9 vaga | 
5 quarto | 
1 suíte | 
Breve Lançamento</p>
                        <p>Oportunidade única! Aproveitem, Área rural plana destinada a pastagem e ainda 50 % de área preservada em mata natural, abundantes<Link to={'https://www.imovelweb.com.br/propriedades/linda-propriedade-em-petropolis-ideal-para-2948983169.html'}>...</Link></p>
                    </div>
                    <div className="icon-parceria">
                        <Link to={'https://www.imovelweb.com.br/'}><img src="../../../img/img-parceria.png" alt="" /></Link>
                        <div className="box-btn">
                            <Link className="btn btn-item btn-primary" to={'tel:21998513964'}><i class="fa fa-phone"></i></Link>
                            <Link className="btn btn-item btn-success" to={'https://wa.link/onasir'}><i class="fa fa-whatsapp"></i></Link>
                            <Link className="btn btn-item btn-danger" to={'https://www.imovelweb.com.br/propriedades/linda-propriedade-em-petropolis-ideal-para-2948983169.html'}>Ver sobre <i class="fa fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="more-pages justify-content-center d-flex">
                <ul className="row links-more-pages">
                <Link to={'../../imovel-fazenda-1'}><li onClick={scrollToTop}>1</li></Link>
                    <Link to={'../../imovel-fazenda-2'}><li onClick={scrollToTop}>2</li></Link>
                </ul>
            </div>
        </section>
    );
}

export default Fazendapg1;