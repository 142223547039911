import React, { useState } from "react";
import { Link } from "react-router-dom";
import './sitio.css';

const ImageModal10 = ({ onClose }) => {

    return (
        <div className="script-modal over">
            <div className="script-modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <br /><br />
                <div>
                    <img src="../../../img/img-imovel11.jpg" alt="" />
                </div>
            </div>
        </div>

    );
}
const ImageModal11 = ({ onClose }) => {

    return (
        <div className="script-modal over">
            <div className="script-modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <br /><br />
                <div>
                    <img src="../../../img/img-imovel12.jpg" alt="" />

                </div>
            </div>
        </div>

    );
}
const ImageModal12 = ({ onClose }) => {

    return (
        <div className="script-modal over">
            <div className="script-modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <br /><br />
                <div>
                    <img src="../../../img/img-imovel13.jpg" alt="" />
                </div>
            </div>
        </div>

    );
}
const ImageModal13 = ({ onClose }) => {

    return (
        <div className="script-modal over">
            <div className="script-modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <br /><br />
                <div>
                    <img src="../../../img/img-imovel14.jpg" alt="" />
                </div>
            </div>
        </div>

    );
}
const ImageModal14 = ({ onClose }) => {

    return (
        <div className="script-modal over">
            <div className="script-modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <br /><br />
                <div>
                    <img src="../../../img/img-imovel15.jpg" alt="" />
                </div>
            </div>
        </div>

    );
}



function Sitiopage() {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const [visibleModal10, setVisibleModal10] = useState(false)

    const abrirModal10 = () => {
        setVisibleModal10(true)
    }
    const fecharModal10 = () => {
        setVisibleModal10(false)
    }
    const [visibleModal11, setVisibleModal11] = useState(false)

    const abrirModal11 = () => {
        setVisibleModal11(true)
    }
    const fecharModal11 = () => {
        setVisibleModal11(false)
    }


    const [visibleModal12, setVisibleModal12] = useState(false)

    const abrirModal12 = () => {
        setVisibleModal12(true)
    }
    const fecharModal12 = () => {
        setVisibleModal12(false)
    }


    const [visibleModal13, setVisibleModal13] = useState(false)

    const abrirModal13 = () => {
        setVisibleModal13(true)
    }
    const fecharModal13 = () => {
        setVisibleModal13(false)
    }


    const [visibleModal14, setVisibleModal14] = useState(false)

    const abrirModal14 = () => {
        setVisibleModal14(true)
    }
    const fecharModal14 = () => {
        setVisibleModal14(false)
    }



    return (
        <section className=" ">
            <div className="row box-item desc-imovel">
                {visibleModal10 && (
                    <ImageModal10 onClose={fecharModal10} />
                )}
                <div className="item-img col-md-3">
                    <Link onClick={abrirModal10}><img src="../../../img/img-imovel11.jpg" alt="" /></Link>
                </div>
                <div className="text-imovel col-md-9">
                    <div className="price-imovel">
                        <h4>R$ 950.000 - R$ 600 / mês</h4>
                        <hr />
                    </div>
                    <div className="local-imovel">
                        <p className="font-weight-bold">VISCONDE DE PIRAJÁ </p>
                        <p></p>
                    </div>
                    <div className="info-imovel">
                        <p>48 m² tot. |
                            48 m² útil |
                            2 quartos |
                            1 banheiro
                        </p>
                        <p>O apartamento está localizado no bairro Ipanema tem 48 metros quadrados com 2 quartos e 1 banheiro<Link to={'https://wa.link/2cy74b'}>...</Link></p>
                    </div>
                    <div className="icon-parceria">
                        <Link to={'https://www.imovelweb.com.br/'}><img src="../../../img/img-parceria.png" alt="" /></Link>
                        <div className="box-btn">
                            <Link className="btn btn-item btn-primary" to={'tel:21998513964'}><i class="fa fa-phone"></i></Link>
                            <Link className="btn btn-item btn-success" to={'https://wa.link/onasir'}><i class="fa fa-whatsapp"></i></Link>
                            <Link className="btn btn-item btn-danger" to={'https://wa.link/2cy74b'}>Ver sobre <i class="fa fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row box-item desc-imovel">
                {visibleModal11 && (
                    <ImageModal11 onClose={fecharModal11} />
                )}
                <div className="item-img col-md-3">
                    <Link onClick={abrirModal11}><img src="../../../img/img-imovel12.jpg" alt="" /></Link>
                </div>
                <div className="text-imovel col-md-9">
                    <div className="price-imovel">
                        <h4>R$ 2.100.000,00</h4>
                        <hr />
                    </div>
                    <div className="local-imovel">
                        <p className="font-weight-bold">COSME VELHO</p>
                        <p></p>
                    </div>
                    <div className="info-imovel">
                        <p>1500 m² ter. |
                            400 m² const. |
                            2 salas |
                            1 vaga |
                            6 quartos |
                            2 suítes |
                            Breve Lançamento</p>
                        <p>Gostaria de conhecer um imóvel único no Cosme Velho? Este guest house incrível possui 6 quartos (sendo 2 suítes), um salão no terceiro <Link to={'https://wa.link/9ckhe4'}>...</Link></p>
                    </div>
                    <div className="icon-parceria">
                        <Link to={'https://www.imovelweb.com.br/'}><img src="../../../img/img-parceria.png" alt="" /></Link>
                        <div className="box-btn">
                            <Link className="btn btn-item btn-primary" to={'tel:21998513964'}><i class="fa fa-phone"></i></Link>
                            <Link className="btn btn-item btn-success" to={'https://wa.link/onasir'}><i class="fa fa-whatsapp"></i></Link>
                            <Link className="btn btn-item btn-danger " to={'https://wa.link/9ckhe4'}>Ver sobre <i class="fa fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row box-item desc-imovel">
                {visibleModal12 && (
                    <ImageModal12 onClose={fecharModal12} />
                )}
                <div className="item-img col-md-3">
                    <Link onClick={abrirModal12}><img src="../../../img/img-imovel13.jpg" alt="" /></Link>
                </div>
                <div className="text-imovel col-md-9">
                    <div className="price-imovel">
                        <h4>R$ 280.000</h4>
                        <hr />
                    </div>
                    <div className="local-imovel">
                        <p className="font-weight-bold">GUAPIMIRIM</p>
                        <p>Parada Modelo, Guapimirim</p>
                    </div>
                    <div className="info-imovel">
                        <p>3800 m² tot. |
                            3800 m² útil |
                            2 banheiros |
                            5 vaga |
                            2 quarto |
                            1 suíte |
                            Breve Lançamento</p>
                        <p>Sítio em Guapimirim - Área nobre da cidade !!! Oportunidade! Guapimirim- 3.800m2 - Área , Nobre da cidade com casa sede em fase de <Link to={'https://www.imovelweb.com.br/propriedades/sitio-em-guapimirim-area-nobre-da-cidade-!!!-2978995332.html'}>...</Link></p>
                    </div>
                    <div className="icon-parceria">
                        <Link to={'https://www.imovelweb.com.br/'}><img src="../../../img/img-parceria.png" alt="" /></Link>
                        <div className="box-btn">
                            <Link className="btn btn-item btn-primary" to={'tel:21998513964'}><i class="fa fa-phone"></i></Link>
                            <Link className="btn btn-item btn-success" to={'https://wa.link/onasir'}><i class="fa fa-whatsapp"></i></Link>
                            <Link className="btn btn-item btn-danger" to={'https://www.imovelweb.com.br/propriedades/sitio-em-guapimirim-area-nobre-da-cidade-!!!-2978995332.html'}>Ver sobre <i class="fa fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row box-item desc-imovel">
                {visibleModal13 && (
                    <ImageModal13 onClose={fecharModal13} />
                )}
                <div className="item-img col-md-3">
                    <Link onClick={abrirModal13}><img src="../../../img/img-imovel14.jpg" alt="" /></Link>
                </div>
                <div className="text-imovel col-md-9">
                    <div className="price-imovel">
                        <h4>R$ 1.700.000 - R$ 2.300</h4>
                        <hr />
                    </div>
                    <div className="local-imovel">
                        <p className="font-weight-bold">LEBLON</p>
                        <p>Rio de Janeiro , Leblon, Rio de Janeiro</p>
                    </div>
                    <div className="info-imovel">
                        <p>60 m² tot. | 
60 m² útil | 
1 banheiro | 
1 vaga | 
2 quarto | 
Breve Lançamento</p>
                        <p>Apartamento no Leblon -RJ ( Zona Sul do Rio de Janeiro), medindo 60m² ,composto de dois quartos , ( podendo virar um loft) <Link to={'https://www.imovelweb.com.br/propriedades/apartamento-no-leblon-zona-sul-do-rio-de-janeiro-2992620753.html'}>...</Link></p>
                    </div>
                    <div className="icon-parceria">
                        <Link to={'https://www.imovelweb.com.br/'}><img src="../../../img/img-parceria.png" alt="" /></Link>
                        <div className="box-btn">
                            <Link className="btn btn-item btn-primary" to={'tel:21998513964'}><i class="fa fa-phone"></i></Link>
                            <Link className="btn btn-item btn-success" to={'https://wa.link/onasir'}><i class="fa fa-whatsapp"></i></Link>
                            <Link className="btn btn-item btn-danger" to={'https://www.imovelweb.com.br/propriedades/apartamento-no-leblon-zona-sul-do-rio-de-janeiro-2992620753.html'}>Ver sobre <i class="fa fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row box-item desc-imovel">
                {visibleModal14 && (
                    <ImageModal14 onClose={fecharModal14} />
                )}
                <div className="item-img col-md-3">
                    <Link onClick={abrirModal14}><img src="../../../img/img-imovel15.jpg" alt="" /></Link>
                </div>
                <div className="text-imovel col-md-9">
                    <div className="price-imovel">
                        <h4>R$ 1.200.000</h4>
                        <hr />
                    </div>
                    <div className="local-imovel">
                        <p className="font-weight-bold">GUAPIMIRIM</p>
                        <p>Centro, Guapimirim</p>
                    </div>
                    <div className="info-imovel">
                        <p>3000 m² tot. | 
3000 m² útil | 
2 banheiros | 
4 vaga | 
3 quarto | 
2 suítes | 
Breve Lançamento</p>
                        <p>Guapimirim ( área nobre da cidade ) , excelente chacara medindo 3.000 m² composta de Casa 1° andar
3 quartos (sendo duas
suites) <Link to={'https://www.imovelweb.com.br/propriedades/chacara-em-guapimirim-area-nobre-da-cidade-2993626597.html'}>...</Link></p>
                    </div>
                    <div className="icon-parceria">
                        <Link to={'https://www.imovelweb.com.br/'}><img src="../../../img/img-parceria.png" alt="" /></Link>
                        <div className="box-btn">
                            <Link className="btn btn-item btn-primary" to={'tel:21998513964'}><i class="fa fa-phone"></i></Link>
                            <Link className="btn btn-item btn-success" to={'https://wa.link/onasir'}><i class="fa fa-whatsapp"></i></Link>
                            <Link className="btn btn-item btn-danger" to={'https://www.imovelweb.com.br/propriedades/chacara-em-guapimirim-area-nobre-da-cidade-2993626597.html'}>Ver sobre <i class="fa fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="more-pages justify-content-center d-flex">
                <ul className="row links-more-pages">
                    <Link to={'../../imovel-sitio-1'}><li onClick={scrollToTop}>1</li></Link>
                    <Link to={'../../imovel-sitio-2'}><li onClick={scrollToTop}>2</li></Link>
                    <Link to={'../../imovel-sitio-3'}><li onClick={scrollToTop}>3</li></Link>
                </ul>
            </div>
        </section>
    );
}

export default Sitiopage;