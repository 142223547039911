import React from "react";

import Navbar2 from "./Inicio/navbar2";
import Footer from "./Inicio/footer";
import ImVenda from "./Imoveis/imovel-venda";

function Venda() {
    return ( <div>
        <Navbar2/>
        <ImVenda/>
        <Footer/>
        </div>
    )
}

export default Venda;