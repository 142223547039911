import React, { useState } from "react";
import { Link } from "react-router-dom";
import './sitio.css';

const ImageModal15 = ({ onClose }) => {

    return (
        <div className="script-modal over">
            <div className="script-modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <br /><br />
                <div>
                    <img src="../../../img/img-imovel16.jpg" alt="" />
                </div>
            </div>
        </div>
    );
}
const ImageModal16 = ({ onClose }) => {

    return (
        <div className="script-modal over">
            <div className="script-modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <br /><br />
                <div>
                <img src="../../../img/img-imovel17.jpg" alt="" />

                </div>
            </div>
        </div>

    );
}
const ImageModal17 = ({ onClose }) => {

    return (
        <div className="script-modal over">
            <div className="script-modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <br /><br />
                <div>
                    <img src="../../../img/img-imovel18.jpg" alt="" />
                </div>
            </div>
        </div>

    );
}
const ImageModal18 = ({ onClose }) => {

    return (
        <div className="script-modal over">
            <div className="script-modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <br /><br />
                <div>
                    <img src="../../../img/img-imovel19.jpg" alt="" />
                </div>
            </div>
        </div>

    );
}
const ImageModal19 = ({ onClose }) => {

    return (
        <div className="script-modal over">
            <div className="script-modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <br /><br />
                <div>
                    <img src="../../../img/img-imovel20.jpg" alt="" />
                </div>
            </div>
        </div>

    );
}


function Sitiopage2() {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const [visibleModal15, setVisibleModal15] = useState(false)

    const abrirModal15 = () => {
        setVisibleModal15(true)
    }
    const fecharModal15 = () => {
        setVisibleModal15(false)
    }
    const [visibleModal16, setVisibleModal16] = useState(false)

    const abrirModal16 = () => {
        setVisibleModal16(true)
    }
    const fecharModal16 = () => {
        setVisibleModal16(false)
    }


    const [visibleModal17, setVisibleModal17] = useState(false)

    const abrirModal17 = () => {
        setVisibleModal17(true)
    }
    const fecharModal17 = () => {
        setVisibleModal17(false)
    }


    const [visibleModal18, setVisibleModal18] = useState(false)

    const abrirModal18 = () => {
        setVisibleModal18(true)
    }
    const fecharModal18 = () => {
        setVisibleModal18(false)
    }


    const [visibleModal19, setVisibleModal19] = useState(false)

    const abrirModal19 = () => {
        setVisibleModal19(true)
    }
    const fecharModal19 = () => {
        setVisibleModal19(false)
    }



    return (
        <section className=" ">
            <div className="row box-item desc-imovel">
            {visibleModal15 && (
                    <ImageModal15 onClose = {fecharModal15}/>
                )}
                <div className="item-img col-md-3">
                    <Link onClick={abrirModal15}><img src="../../../img/img-imovel16.jpg" alt="" /></Link>
                </div>
                <div className="text-imovel col-md-9">
                    <div className="price-imovel">
                        <h4>R$ 2.300.000</h4>
                        <hr />
                    </div>
                    <div className="local-imovel">
                        <p className="font-weight-bold">GUAPIMIRIM</p>
                        <p>Parque Silvestre, Guapimirim</p>
                    </div>
                    <div className="info-imovel">
                        <p>2100 m² tot. | 
2100 m² útil | 
3 banheiros | 
5 vaga | 
3 quarto | 
2 suítes | 
Breve Lançamento</p>
                        <p>Guapimirim- Subida da serra de Teresópolis- Excelente casa composta de uma linda Casa Principal, composta de: sala(50 m2) três quartos <Link to={'https://www.imovelweb.com.br/propriedades/excelente-casa-em-guapimirim-subida-da-serra-de-2955029219.html'}>...</Link></p>
                    </div>
                    <div className="icon-parceria">
                        <Link to={'https://www.imovelweb.com.br/'}><img src="../../../img/img-parceria.png" alt="" /></Link>
                        <div className="box-btn">
                            <Link className="btn btn-item btn-primary" to={'tel:21998513964'}><i class="fa fa-phone"></i></Link>
                            <Link className="btn btn-item btn-success" to={'https://wa.link/onasir'}><i class="fa fa-whatsapp"></i></Link>
                            <Link className="btn btn-item btn-danger" to={'https://www.imovelweb.com.br/propriedades/excelente-casa-em-guapimirim-subida-da-serra-de-2955029219.html'}>Ver sobre <i class="fa fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row box-item desc-imovel">
            {visibleModal16 && (
                    <ImageModal16 onClose = {fecharModal16}/>
                )}
                <div className="item-img col-md-3">
                    <Link onClick={abrirModal16}><img src="../../../img/img-imovel17.jpg" alt="" /></Link>
                </div>
                <div className="text-imovel col-md-9">
                    <div className="price-imovel">
                        <h4>R$ 420.000</h4>
                        <hr />
                    </div>
                    <div className="local-imovel">
                        <p className="font-weight-bold">GUAPIMIRIM</p>
                        <p>Parada Modelo , Parada Ideal, Guapimirim</p>
                    </div>
                    <div className="info-imovel">
                        <p>1000 m² tot. | 
1000 m² útil | 
2 banheiros | 
40 vaga | 
3 quarto | 
Breve Lançamento</p>
                        <p>Linda casa em Guapimirim , Parada Modelo, casa com terreno medindo 1.000m2 toda murada com Área de lazer, piscina, dois andares casa  <Link to={'https://www.imovelweb.com.br/propriedades/casa-mini-chacara-em-guapimirim-parada-modelo-2989883587.html'}>...</Link></p>
                    </div>
                    <div className="icon-parceria">
                        <Link to={'https://www.imovelweb.com.br/'}><img src="../../../img/img-parceria.png" alt="" /></Link>
                        <div className="box-btn">
                            <Link className="btn btn-item btn-primary" to={'tel:21998513964'}><i class="fa fa-phone"></i></Link>
                            <Link className="btn btn-item btn-success" to={'https://wa.link/onasir'}><i class="fa fa-whatsapp"></i></Link>
                            <Link className="btn btn-item btn-danger " to={'https://www.imovelweb.com.br/propriedades/casa-mini-chacara-em-guapimirim-parada-modelo-2989883587.html'}>Ver sobre <i class="fa fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row box-item desc-imovel">
            {visibleModal17 && (
                    <ImageModal17 onClose = {fecharModal17}/>
                )}
                <div className="item-img col-md-3">
                    <Link onClick={abrirModal17}><img src="../../../img/img-imovel18.jpg" alt="" /></Link>
                </div>
                <div className="text-imovel col-md-9">
                    <div className="price-imovel">
                        <h4>R$ 2.500.000</h4>
                        <hr />
                    </div>
                    <div className="local-imovel">
                        <p className="font-weight-bold">ILHA DO GOVERNADOR</p>
                        <p>Rio de Janeiro , Jardim Carioca, Rio de Janeiro</p>
                    </div>
                    <div className="info-imovel">
                        <p>600 m² tot. | 
600 m² útil | 
4 banheiros | 
8 vaga | 
4 quarto | 
3 suítes | 
Breve Lançamento</p>
                        <p>Casa situada em área nobre da Ilha do Governador - RJ , distribuída em uma entrada com hall de entrada externo 4 quartos sendo uma <Link to={'https://www.imovelweb.com.br/propriedades/casa-mansao-localizada-na-iha-do-governador-2978781546.html'}>...</Link></p>
                    </div>
                    <div className="icon-parceria">
                        <Link to={'https://www.imovelweb.com.br/'}><img src="../../../img/img-parceria.png" alt="" /></Link>
                        <div className="box-btn">
                            <Link className="btn btn-item btn-primary" to={'tel:21998513964'}><i class="fa fa-phone"></i></Link>
                            <Link className="btn btn-item btn-success" to={'https://wa.link/onasir'}><i class="fa fa-whatsapp"></i></Link>
                            <Link className="btn btn-item btn-danger" to={'https://www.imovelweb.com.br/propriedades/casa-mansao-localizada-na-iha-do-governador-2978781546.html'}>Ver sobre <i class="fa fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row box-item desc-imovel">
            {visibleModal18 && (
                    <ImageModal18 onClose = {fecharModal18}/>
                )}
                <div className="item-img col-md-3">
                    <Link onClick={abrirModal18}><img src="../../../img/img-imovel19.jpg" alt="" /></Link>
                </div>
                <div className="text-imovel col-md-9">
                    <div className="price-imovel">
                        <h4>R$ 280.000</h4>
                        <hr />
                    </div>
                    <div className="local-imovel">
                        <p className="font-weight-bold">GUAPIMIRIM</p>
                        <p>Parada Ideal , Parada Ideal, Guapimirim</p>
                    </div>
                    <div className="info-imovel">
                        <p>2400 m² tot. | 
2400 m² útil | 
1 banheiro | 
8 vaga | 
2 quarto | 
Breve Lançamento</p>
                        <p>Guapimirim - Excelente chácara medindo 2.400m2 composta de Casa com 2 quartos, sala, cozinha, banheiro, varanda, e área de serviço <Link to={'https://www.imovelweb.com.br/propriedades/chacara-em-guapimirim-medindo-2.400m2-2979074461.html'}>...</Link></p>
                    </div>
                    <div className="icon-parceria">
                        <Link to={'https://www.imovelweb.com.br/'}><img src="../../../img/img-parceria.png" alt="" /></Link>
                        <div className="box-btn">
                            <Link className="btn btn-item btn-primary" to={'tel:21998513964'}><i class="fa fa-phone"></i></Link>
                            <Link className="btn btn-item btn-success" to={'https://wa.link/onasir'}><i class="fa fa-whatsapp"></i></Link>
                            <Link className="btn btn-item btn-danger" to={'https://www.imovelweb.com.br/propriedades/chacara-em-guapimirim-medindo-2.400m2-2979074461.html'}>Ver sobre <i class="fa fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row box-item desc-imovel">
            {visibleModal19 && (
                    <ImageModal19 onClose = {fecharModal19}/>
                )}
                <div className="item-img col-md-3">
                    <Link onClick={abrirModal19}><img src="../../../img/img-imovel20.jpg" alt="" /></Link>
                </div>
                <div className="text-imovel col-md-9">
                    <div className="price-imovel">
                        <h4>R$ 600.000</h4>
                        <hr />
                    </div>
                    <div className="local-imovel">
                        <p className="font-weight-bold">GUAPIMIRIM</p>
                        <p>Centro, Guapimirim</p>
                    </div>
                    <div className="info-imovel">
                        <p>7550 m² tot. | 
7550 m² útil | 
2 banheiros | 
8 vaga | 
4 quarto | 
1 suíte | 
Breve Lançamento</p>
                        <p>sítio em Parada Modelo possui uma área total de 7.550 m² e é composto por uma casa duplex. No 1º pavimento, encontramos uma sala <Link to={'https://www.imovelweb.com.br/propriedades/sitio-em-guapimirim-2980977224.html'}>...</Link></p>
                    </div>
                    <div className="icon-parceria">
                        <Link to={'https://www.imovelweb.com.br/'}><img src="../../../img/img-parceria.png" alt="" /></Link>
                        <div className="box-btn">
                            <Link className="btn btn-item btn-primary" to={'tel:21998513964'}><i class="fa fa-phone"></i></Link>
                            <Link className="btn btn-item btn-success" to={'https://wa.link/onasir'}><i class="fa fa-whatsapp"></i></Link>
                            <Link className="btn btn-item btn-danger" to={'https://www.imovelweb.com.br/propriedades/sitio-em-guapimirim-2980977224.html'}>Ver sobre <i class="fa fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="more-pages justify-content-center d-flex">
                <ul className="row links-more-pages">
                <Link to={'../../imovel-sitio-1'}><li onClick={scrollToTop}>1</li></Link>
                    <Link to={'../../imovel-sitio-2'}><li onClick={scrollToTop}>2</li></Link>
                    <Link to={'../../imovel-sitio-3'}><li onClick={scrollToTop}>3</li></Link>
                </ul>
            </div>
        </section>
    );
}

export default Sitiopage2;