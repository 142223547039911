import React from "react";

import Navbar from "./Inicio/navbar";
import Sobre from "./Inicio/sobre";
import Itens from "./Inicio/itens";
import Parcerias from "./Inicio/parcerias";
import Avaliacao from "./Inicio/avaliacao";
import Map from "./Inicio/map";
import Footer from "./Inicio/footer";

function Site() {
    return ( <div>
        <Navbar/>
        <Sobre/>
        <Itens/>
        <Parcerias/>
        <Avaliacao/>
        <Map/>
        <Footer/>
        </div>
    )
}

export default Site;