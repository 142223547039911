import React from "react";
import './map.css';

function Map() {
    return (
        <div class="container-map" id="localizacao">
            <h1 class="text-center p-1" data-aos="fade-up" data-aos-delay="100">Localização</h1>
            <div class="container row itens-map">
                <div class="map-container">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3684.5149120224387!2d-42.86303862468095!3d-22.559837225563694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x99b1a978a172a1%3A0x7e443ea40b8a308e!2sDouglas%20Rastrelli%20Corretor%20De%20Im%C3%B3veis!5e0!3m2!1spt-BR!2sbr!4v1715178414740!5m2!1spt-BR!2sbr" width="600" height="450"allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div class="info-container text-center" data-aos="fade-up" data-aos-delay="100">
                    <h3 class="pb-2"><b>Endereço</b></h3>
                    <p class="pb-2">Rod. RJ 122 - Estrada Rio Friburgo, km 18 - <br/> Cachoeiras de Macacu RJ - 28.690-00</p>
                    <h3 class="pb-2"><b>Confira nossos horários</b></h3>
                    <p>Segunda a Sábado: 08h00 - 17h00</p>

                </div>
            </div>
        </div>
    );
}

export default Map;