import React, { useState } from "react";
import { Link } from "react-router-dom";
import './fazenda-pg1';

const ImageModal05 = ({ onClose }) => {

    return (
        <div className="script-modal over">
            <div className="script-modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <br /><br />
                <div>
                    <img src="../../../img/img-imovel6.jpg" alt="" />
                </div>
            </div>
        </div>

    );
}
const ImageModal06 = ({ onClose }) => {

    return (
        <div className="script-modal over">
            <div className="script-modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <br /><br />
                <div>
                <img src="../../../img/img-imovel7.jpg" alt="" />

                </div>
            </div>
        </div>

    );
}
const ImageModal07 = ({ onClose }) => {

    return (
        <div className="script-modal over">
            <div className="script-modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <br /><br />
                <div>
                    <img src="../../../img/img-imovel8.jpg" alt="" />
                </div>
            </div>
        </div>

    );
}
const ImageModal08 = ({ onClose }) => {

    return (
        <div className="script-modal over">
            <div className="script-modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <br /><br />
                <div>
                    <img src="../../../img/img-imovel9.jpg" alt="" />
                </div>
            </div>
        </div>

    );
}
const ImageModal09 = ({ onClose }) => {

    return (
        <div className="script-modal over">
            <div className="script-modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <br /><br />
                <div>
                    <img src="../../../img/img-imovel10.jpg" alt="" />
                </div>
            </div>
        </div>

    );
}

    



function Fazendapg2() {


    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const [visibleModal05, setVisibleModal05] = useState(false)

    const abrirModal05 = () => {
        setVisibleModal05(true)
    }
    const fecharModal05 = () => {
        setVisibleModal05(false)
    }
    const [visibleModal06, setVisibleModal06] = useState(false)

    const abrirModal06 = () => {
        setVisibleModal06(true)
    }
    const fecharModal06 = () => {
        setVisibleModal06(false)
    }


    const [visibleModal07, setVisibleModal07] = useState(false)

    const abrirModal07 = () => {
        setVisibleModal07(true)
    }
    const fecharModal07 = () => {
        setVisibleModal07(false)
    }


    const [visibleModal08, setVisibleModal08] = useState(false)

    const abrirModal08 = () => {
        setVisibleModal08(true)
    }
    const fecharModal08 = () => {
        setVisibleModal08(false)
    }


    const [visibleModal09, setVisibleModal09] = useState(false)

    const abrirModal09 = () => {
        setVisibleModal09(true)
    }
    const fecharModal09 = () => {
        setVisibleModal09(false)
    }

    return (
        <section id="imovel-pg2">
            <div className="row box-item desc-imovel">
            {visibleModal05 && (
                    <ImageModal05 onClose = {fecharModal05}/>
                )}
                <div className="item-img col-md-3">
                    <Link onClick={abrirModal05}><img src="../../../img/img-imovel6.jpg" alt="" /></Link>
                </div>
                <div className="text-imovel col-md-9">
                    <div className="price-imovel">
                        <h4>R$ 9.000.000</h4>
                        <hr />
                    </div>
                    <div className="local-imovel">
                        <p className="font-weight-bold">TERESÓPOLIS</p>
                        <p>Araras, Teresópolis</p>
                    </div>
                    <div className="info-imovel">
                        <p>968000 m² tot. | 
968000 m² útil | 
8 banheiros | 
15 vaga | 
10 quarto | 
5 suítes | 
Breve Lançamento</p>
                        <p>Lindo Haras ( para clientes exigentes) na região serrana ( Área Nobre) medindo 20 alqueires ( 48.400 cada alqueire), composto de 100 baias <Link to={'https://www.imovelweb.com.br/propriedades/haras-na-regiao-serrana-do-estado-do-rj-2979694035.html'}>...</Link></p>
                    </div>
                    <div className="icon-parceria">
                        <Link to={'https://www.imovelweb.com.br/'}><img src="../../../img/img-parceria.png" alt="" /></Link>
                        <div className="box-btn">
                            <Link className="btn btn-item btn-primary" to={'tel:21998513964'}><i class="fa fa-phone"></i></Link>
                            <Link className="btn btn-item btn-success" to={'https://wa.link/onasir'}><i class="fa fa-whatsapp"></i></Link>
                            <Link className="btn btn-item btn-danger" to={'https://www.imovelweb.com.br/propriedades/haras-na-regiao-serrana-do-estado-do-rj-2979694035.html'}>Ver sobre <i class="fa fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row box-item desc-imovel">
            {visibleModal06 && (
                    <ImageModal06 onClose = {fecharModal06}/>
                )}
                <div className="item-img col-md-3">
                    <Link onClick={abrirModal06}><img src="../../../img/img-imovel7.jpg" alt="" /></Link>
                </div>
                <div className="text-imovel col-md-9">
                    <div className="price-imovel">
                        <h4>R$ 15.000.000</h4>
                        <hr />
                    </div>
                    <div className="local-imovel">
                        <p className="font-weight-bold">GUAPIMIRIM</p>
                        <p>Parada Modelo, Guapimirim</p>
                    </div>
                    <div className="info-imovel">
                        <p>6292000 m² tot. | 
6292000 m² útil | 
9 vaga | 
2 quarto | 
Breve Lançamento</p>
                        <p>Medindo 130 alqueires geométricos, pastagens com capacidade para 1.000 cabeças, toda cercada, vários piquetes, Rio dentro da fazenda<Link to={'https://www.imovelweb.com.br/propriedades/fazenda-em-guapimirim-rj-2981064922.html'}>...</Link></p>
                    </div>
                    <div className="icon-parceria">
                        <Link to={'https://www.imovelweb.com.br/'}><img src="../../../img/img-parceria.png" alt="" /></Link>
                        <div className="box-btn">
                        <Link className="btn btn-item btn-primary" to={'tel:21998513964'}><i class="fa fa-phone"></i></Link>
                            <Link className="btn btn-item btn-success" to={'https://wa.link/onasir'}><i class="fa fa-whatsapp"></i></Link>
                            <Link className="btn btn-item btn-danger " to={'https://www.imovelweb.com.br/propriedades/fazenda-em-guapimirim-rj-2981064922.html'}>Ver sobre <i class="fa fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row box-item desc-imovel">
            {visibleModal07 && (
                    <ImageModal07 onClose = {fecharModal07}/>
                )}
                <div className="item-img col-md-3">
                    <Link onClick={abrirModal07}><img src="../../../img/img-imovel8.jpg" alt="" /></Link>
                </div>
                <div className="text-imovel col-md-9">
                    <div className="price-imovel">
                        <h4>R$ 2.500.000</h4>
                        <hr />
                    </div>
                    <div className="local-imovel">
                        <p className="font-weight-bold">BOM JARDIM</p>
                        <p>Região Serrana do Rio de Janeiro -RJ , Centro, Bom Jardim</p>
                    </div>
                    <div className="info-imovel">
                        <p>598950 m² tot. | 
598950 m² útil | 
5 banheiros | 
3 vaga | 
4 quarto | 
2 suítes | 
Breve Lançamento</p>
                        <p>Oportunidade IMPERDÍVEL para quem deseja desenvolver atividade leiteira, explorar atividade hoteleira (em razão dos chalés já <Link to={'https://www.imovelweb.com.br/propriedades/fazenda-em-bom-jardim-2993092934.html'}>...</Link></p>
                    </div>
                    <div className="icon-parceria">
                        <Link to={'https://www.imovelweb.com.br/'}><img src="../../../img/img-parceria.png" alt="" /></Link>
                        <div className="box-btn">
                        <Link className="btn btn-item btn-primary" to={'tel:21998513964'}><i class="fa fa-phone"></i></Link>
                            <Link className="btn btn-item btn-success" to={'https://wa.link/onasir'}><i class="fa fa-whatsapp"></i></Link>
                            <Link className="btn btn-item btn-danger" to={'https://www.imovelweb.com.br/propriedades/fazenda-em-bom-jardim-2993092934.html'}>Ver sobre <i class="fa fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row box-item desc-imovel">
            {visibleModal08 && (
                    <ImageModal08 onClose = {fecharModal08}/>
                )}
                <div className="item-img col-md-3">
                    <Link onClick={abrirModal08}><img src="../../../img/img-imovel9.jpg" alt="" /></Link>
                </div>
                <div className="text-imovel col-md-9">
                    <div className="price-imovel">
                        <h4>R$ 60.000.000</h4>
                        <hr />
                    </div>
                    <div className="local-imovel">
                        <p className="font-weight-bold">NÃO PREENCHIDO</p>
                        <p>NÃO PREENCHIDO</p>
                    </div>
                    <div className="info-imovel">
                        <p>9680000 m² tot. | 
9680000 m² útil | 
9 banheiros | 
9 vaga | 
9 quarto | 
8 suítes |  
Breve Lançamento</p>
                        <p> ( Excelente localização) medindo 200 alqueires geométricos, 70% baixada, pasto para 3.000 cabeças de gado, 6 casas de colonos <Link to={'https://www.imovelweb.com.br/propriedades/fazenda-medindo-200-alqueires-geometricos-em-2987998217.html'}>...</Link></p>
                    </div>
                    <div className="icon-parceria">
                        <Link to={'https://www.imovelweb.com.br/'}><img src="../../../img/img-parceria.png" alt="" /></Link>
                        <div className="box-btn">
                        <Link className="btn btn-item btn-primary" to={'tel:21998513964'}><i class="fa fa-phone"></i></Link>
                            <Link className="btn btn-item btn-success" to={'https://wa.link/onasir'}><i class="fa fa-whatsapp"></i></Link>
                            <Link className="btn btn-item btn-danger" to={'https://www.imovelweb.com.br/propriedades/fazenda-medindo-200-alqueires-geometricos-em-2987998217.html'}>Ver sobre <i class="fa fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="row box-item desc-imovel">
            {visibleModal09 && (
                    <ImageModal09 onClose = {fecharModal09}/>
                )}
                <div className="item-img col-md-3">
                    <Link onClick={abrirModal09}><img src="../../../img/img-imovel10.jpg" alt="" /></Link>
                </div>
                <div className="text-imovel col-md-9">
                    <div className="price-imovel">
                        <h4>R$ 450.000</h4>
                        <hr />
                    </div>
                    <div className="local-imovel">
                        <p className="font-weight-bold">GUAPIMIRIM</p>
                        <p>Parada Modelo , Parada Modelo, Guapimirim</p>
                    </div>
                    <div className="info-imovel">
                        <p>2400 m² tot. | 
2400 m² útil | 
1 banheiro | 
5 vaga | 
3 quarto | 
Breve Lançamento</p>
                        <p>Lindo sítio em Guapimirim - Parada Modelo Medindo 2.400m2 com área gourmet, piscina, churrasqueira, casa sede boa composta de 3<Link to={'https://www.imovelweb.com.br/propriedades/sitio-em-guapimirim-rj-2981062552.html'}>...</Link></p>
                    </div>
                    <div className="icon-parceria">
                        <Link to={'https://www.imovelweb.com.br/'}><img src="../../../img/img-parceria.png" alt="" /></Link>
                        <div className="box-btn">
                        <Link className="btn btn-item btn-primary" to={'tel:21998513964'}><i class="fa fa-phone"></i></Link>
                            <Link className="btn btn-item btn-success" to={'https://wa.link/onasir'}><i class="fa fa-whatsapp"></i></Link>
                            <Link className="btn btn-item btn-danger" to={'https://www.imovelweb.com.br/propriedades/sitio-em-guapimirim-rj-2981062552.html'}>Ver sobre <i class="fa fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="more-pages justify-content-center d-flex">
                <ul className="row links-more-pages">
                <Link to={'../../imovel-fazenda-1'}><li onClick={scrollToTop}>1</li></Link>
                    <Link to={'../../imovel-fazenda-2'}><li onClick={scrollToTop}>2</li></Link>
                </ul>
            </div>
        </section>
    );
}

export default Fazendapg2;