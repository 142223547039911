import React from "react";
import './sobre.css';

function Sobre() {

    return (
        <section id="sobre" className="section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-xs-12 valores">
                        <div className="services-item text-center">
                            <div className="icon">
                                <i className="fa fa-bullseye"></i>
                            </div>
                            <h4>Missão</h4>
                            <p>Facilitar a realização dos sonhos imobiliários dos clientes, oferecendo serviços de corretagem confiáveis, personalizados e eficientes.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-xs-12 valores">
                        <div className="services-item text-center">
                            <div className="icon">
                                <i className="fa fa-eye"></i>
                            </div>
                            <h4>Visão</h4>
                            <p>Ser reconhecida como a principal parceira dos clientes na realização de seus sonhos e objetivos imobiliários, destacando-se pela excelência no atendimento, transparência, inovação e compromisso com a satisfação dos clientes.</p>
                        </div>
                    </div>


                    <div className="col-lg-4 col-md-6 col-xs-12 valores">
                        <div className="services-item text-center">
                            <div className="icon">
                                <i className="fa fa-shield"></i>
                            </div>
                            <h4>Valores</h4>
                            <p>Excelência: Buscar constantemente a excelência em todos os aspectos do negócio, desde a qualidade dos serviços até a eficiência operacional.</p> <br />
                        </div>
                    </div>
                </div>
                <div className="business-plan">
                    <div className="row ">
                        <div className="col-lg-6 col-md-12 pl-0 pt-70 pr-5">
                            <div className="business-item-img">
                                <img src="../../../img/img-sobre.png" className="img-fluid" />
                            </div>
                        </div>


                        <div className="col-lg-6 col-md-12 pl-4">
                            <div className="business-item-info">
                                <h3 className="text-center">
                                Douglas Rastrelli: Sua Parceira de Confiança para o Mercado Imobiliário
                                </h3>
                                <p className=" text-justify">Localizada no coração do Rio de Janeiro, a Douglas Rastrelli é uma corretora de imóveis dedicada a oferecer soluções personalizadas para todas as suas necessidades imobiliárias. Desde apartamentos à beira-mar até casas charmosas em bairros históricos, nossa equipe experiente está aqui para ajudá-lo a encontrar o lar dos seus sonhos na deslumbrante cidade do Rio. <br/><br/>

                                    Nossa missão é mais do que apenas fechar negócios; é sobre construir relacionamentos duradouros com nossos clientes, entendendo suas necessidades únicas e superando suas expectativas. Com um foco inabalável na excelência, integridade e profissionalismo, estamos comprometidos em proporcionar uma experiência imobiliária excepcional, desde o primeiro contato até o momento em que você abre as portas da sua nova casa.<br/><br/>

                                    Na Douglas Rastrelli, valorizamos cada cliente e cada propriedade, e buscamos sempre a excelência em tudo o que fazemos. Se você está procurando comprar, vender ou alugar uma propriedade no Rio de Janeiro, conte conosco para fornecer orientação especializada e um serviço personalizado que o ajudará a alcançar seus objetivos imobiliários.<br/><br/>

                                    Venha nos visitar em nossa sede no Rio de Janeiro ou entre em contato conosco hoje mesmo para descobrir como podemos ajudá-lo a transformar seus sonhos imobiliários em realidade. Seja bem-vindo à Douglas Rastrelli - onde o Rio é mais do que apenas um lugar, é o lar.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default Sobre;