import React from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import './navbar2.css';

function Navbar2(){

    return ( 
        <header id="home" className="hero-area">
    <div className="overlay">
        
    </div>
    <nav className="navbar navbar-expand-md bg-inverse sticky-top scrolling-navbar">
                <div className="container">
                    <a href="#home" className="navbar-brand"><img src="../../../img/img-logo.png" alt/></a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                            aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fa fa-bars"></i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav mr-auto w-100 justify-content-end text-center">
                            <li className="nav-item">
                                <RouterLink className="nav-link" to="/" smooth={true} duration={700}>Inicio</RouterLink>
                            </li>
                            <li className="nav-item">
                                <RouterLink className="nav-link" to="/" smooth={true} duration={700}>Sobre</RouterLink>
                            </li>
                            <li className="nav-item">
                                <RouterLink className="nav-link" to='/'>Imóveis</RouterLink>
                            </li>
                            <li className="nav-item">
                                <RouterLink className="nav-link" to="/" smooth={true} duration={700}>Parcerias</RouterLink>
                            </li>
                            <li className="nav-item">
                                <RouterLink className="nav-link" to="/" smooth={true} duration={700}>Avaliações</RouterLink>
                            </li>
                            <li className="nav-item">
                                <RouterLink className="nav-link" to="/" smooth={true} duration={700}>Contatos</RouterLink>
                            </li>
                            <li className="nav-item">
                                <a className="btn btn-singin" href="https://wa.link/iod1ow"><i className="fa fa-whatsapp"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
</header>
    );
}

export default Navbar2;