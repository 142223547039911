import React from "react";
import './imovel-venda.css';

function ImVenda() {

    return (
        <section>
            <div>
                <h3 className="text-center text-dark py-3">Imóvel 1</h3>
                <a href="https://www.imovelweb.com.br/propriedades/sitio-propriedade-em-cachoeiras-de-macacu-2948630371.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/sitio-propriedade-em-cachoeiras-de-macacu-2948630371.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 2</h3>
                <a href="https://www.imovelweb.com.br/propriedades/fazenda-em-cachoeiras-de-macacu-rj-122-2947571998.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/fazenda-em-cachoeiras-de-macacu-rj-122-2947571998.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 3</h3>
                <a href="https://www.imovelweb.com.br/propriedades/lote-terreno-em-sao-pedro-da-aldeia-2958726055.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/lote-terreno-em-sao-pedro-da-aldeia-2958726055.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 4</h3>
                <a href="https://www.imovelweb.com.br/propriedades/terreno-e-chale-em-guapimirim-com-cachoeira-2995003378.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/terreno-e-chale-em-guapimirim-com-cachoeira-2995003378.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 5</h3>
                <a href="https://www.imovelweb.com.br/propriedades/fazenda-em-cachoeiras-de-macacu-rj-122-estrada-rio-2992561727.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/fazenda-em-cachoeiras-de-macacu-rj-122-estrada-rio-2992561727.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 6</h3>
                <a href="https://www.imovelweb.com.br/propriedades/hotel-a-venda-em-macae-regiao-dos-lagos-rj-2979790746.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/hotel-a-venda-em-macae-regiao-dos-lagos-rj-2979790746.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 7</h3>
                <a href="https://www.imovelweb.com.br/propriedades/sitio-propriedade-em-cachoeiras-de-macacu-rj-116-2995073641.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/sitio-propriedade-em-cachoeiras-de-macacu-rj-116-2995073641.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 8</h3>
                <a href="https://www.imovelweb.com.br/propriedades/chacara-em-guapimirim-2988772152.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/chacara-em-guapimirim-2988772152.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 9</h3>
                <a href="https://www.imovelweb.com.br/propriedades/chacara-em-guapimirim-2988773725.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/chacara-em-guapimirim-2988773725.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 10</h3>
                <a href="https://www.imovelweb.com.br/propriedades/sitio-chacara-em-guapimirim-com-cachoeira-particular-2991858306.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/sitio-chacara-em-guapimirim-com-cachoeira-particular-2991858306.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 11</h3>
                <a href="https://www.imovelweb.com.br/propriedades/fazendinha-em-teresopolis-medindo-230.000m-2994182644.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/fazendinha-em-teresopolis-medindo-230.000m-2994182644.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 12</h3>
                <a href="https://www.imovelweb.com.br/propriedades/sitio-propriedade-na-rj-116-cachoeira-cortando-a-2990064737.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/sitio-propriedade-na-rj-116-cachoeira-cortando-a-2990064737.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 13</h3>
                <a href="https://www.imovelweb.com.br/propriedades/fazenda-em-cachoeiras-de-macacu-rj-2980897173.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/fazenda-em-cachoeiras-de-macacu-rj-2980897173.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 14</h3>
                <a href="https://www.imovelweb.com.br/propriedades/linda-propriedade-em-petropolis-ideal-para-2948983169.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/linda-propriedade-em-petropolis-ideal-para-2948983169.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 15</h3>
                <a href="https://www.imovelweb.com.br/propriedades/sitio-chacara-em-guapimirim-area-nobre-da-cidade-2978760731.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/sitio-chacara-em-guapimirim-area-nobre-da-cidade-2978760731.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 16</h3>
                <a href="https://www.imovelweb.com.br/propriedades/chacara-em-guapimirim-area-nobre-da-cidade-2993626597.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/chacara-em-guapimirim-area-nobre-da-cidade-2993626597.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 17</h3>
                <a href="https://www.imovelweb.com.br/propriedades/excelente-casa-em-guapimirim-subida-da-serra-de-2955029219.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/excelente-casa-em-guapimirim-subida-da-serra-de-2955029219.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 18</h3>
                <a href="https://www.imovelweb.com.br/propriedades/haras-na-regiao-serrana-do-estado-do-rj-2979694035.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/haras-na-regiao-serrana-do-estado-do-rj-2979694035.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 19</h3>
                <a href="https://www.imovelweb.com.br/propriedades/linda-propriedade-em-silva-jardim-medindo-3-5-2967894144.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/linda-propriedade-em-silva-jardim-medindo-3-5-2967894144.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 20</h3>
                <a href="https://www.imovelweb.com.br/propriedades/casa-mini-chacara-em-guapimirim-parada-modelo-2989883587.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/casa-mini-chacara-em-guapimirim-parada-modelo-2989883587.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 21</h3>
                <a href="https://www.imovelweb.com.br/propriedades/apartamento-no-leblon-zona-sul-do-rio-de-janeiro-2992620753.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/apartamento-no-leblon-zona-sul-do-rio-de-janeiro-2992620753.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 22</h3>
                <a href="https://www.imovelweb.com.br/propriedades/casa-mansao-localizada-na-iha-do-governador-2978781546.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/casa-mansao-localizada-na-iha-do-governador-2978781546.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 23</h3>
                <a href="https://www.imovelweb.com.br/propriedades/fazenda-em-guapimirim-rj-2981064922.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/fazenda-em-guapimirim-rj-2981064922.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 24</h3>
                <a href="https://www.imovelweb.com.br/propriedades/sitio-em-guapimirim-2980977224.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/sitio-em-guapimirim-2980977224.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 25</h3>
                <a href="https://www.imovelweb.com.br/propriedades/sitio-em-teresopolis-regiao-serrana-do-estado-do-rio-2993030948.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/sitio-em-teresopolis-regiao-serrana-do-estado-do-rio-2993030948.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 26</h3>
                <a href="https://www.imovelweb.com.br/propriedades/fazenda-em-bom-jardim-2993092934.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/fazenda-em-bom-jardim-2993092934.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 27</h3>
                <a href="https://www.imovelweb.com.br/propriedades/sitio-em-guapimirim-area-nobre-da-cidade-!!!-2978995332.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/sitio-em-guapimirim-area-nobre-da-cidade-!!!-2978995332.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 28</h3>
                <a href="https://www.imovelweb.com.br/propriedades/chacara-em-guapimirim-medindo-2.400m2-2979074461.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/chacara-em-guapimirim-medindo-2.400m2-2979074461.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 29</h3>
                <a href="https://www.imovelweb.com.br/propriedades/linda-chacara-em-cachoeiras-de-macacu-rj-122-medindo-2975561886.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/linda-chacara-em-cachoeiras-de-macacu-rj-122-medindo-2975561886.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 30</h3>
                <a href="https://www.imovelweb.com.br/propriedades/casa-em-cabo-frio-no-canal-2992621318.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/casa-em-cabo-frio-no-canal-2992621318.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 31</h3>
                <a href="https://www.imovelweb.com.br/propriedades/fazenda-medindo-200-alqueires-geometricos-em-2987998217.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/fazenda-medindo-200-alqueires-geometricos-em-2987998217.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 32</h3>
                <a href="https://www.imovelweb.com.br/propriedades/sitio-para-lazer-em-sambaetiba-rj-116-2992682976.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/sitio-para-lazer-em-sambaetiba-rj-116-2992682976.html</p></u>
                </a>
            </div>

            <div>
            <h3 className="text-center text-dark py-3">Imóvel 33</h3>
                <a href="https://www.imovelweb.com.br/propriedades/casa-em-buzios-geriba-2991492544.html">
                    <u><p className="text-center py-4">https://www.imovelweb.com.br/propriedades/casa-em-buzios-geriba-2991492544.html</p></u>
                </a>
            </div>
            
        </section>
    );
}

export default ImVenda;