import React from "react";
import { Link } from "react-router-dom";
import './itens.css';


function Itens() {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };


    return (
        <section id="Itens">
            <div>
                <h1 className="text-center">Imóveis Disponíveis</h1>
            </div>
            <div className="container text-white">
                <div className="row imoveis justify-space-around">
                    <div className="text-center  py-5">
                        <div className="item-imovel">
                            <div className="img-imovel1">
                            </div>
                            <h4 className="pb-4 pt-3">Sítios | Casas | Chácaras </h4>
                            <p>Nossos sítios, casas, chácaras e apartamentos disponíveis e descubra seu novo lar!</p>
                            <Link to='./imovel-sitio-1' onClick={scrollToTop} className="btn btn-veja btn-primary">Veja Mais</Link>
                        </div>
                    </div>
                    <div className="text-center py-5">
                        <div className="item-imovel ">
                            <div className="img-imovel2">
                            </div>
                            <h4 className="pb-4 pt-3">Fazendas</h4>
                            <p>Descubra a fazenda dos seus sonhos e comece uma nova vida no campo hoje mesmo!</p>
                            <Link to='./imovel-fazenda-1' onClick={scrollToTop} className="btn btn-veja btn-primary">Veja Mais</Link>
                        </div>
                    </div><div className="text-center py-5">
                        <div className="item-imovel ">
                            <div className="img-imovel4">
                            </div>
                            <h4 className="pb-4 pt-3">Imóveis para venda</h4>
                            <p>Confira nossos imóveis à venda e encontre seu novo lar!</p>
                            <Link to='./imovel-venda' onClick={scrollToTop} className="btn btn-veja btn-primary">Veja Mais</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Itens;