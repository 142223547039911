import React from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import './navbar.css';

function Navbar(){
/*Fazer o menu abrir e fechar devagar */



    return ( 
        <header id="home" className="hero-area">
    <div className="overlay">
        <span ></span>
        <span></span>
    </div>
    <nav className="navbar navbar-expand-md bg-inverse fixed-top scrolling-navbar">
                <div className="container">
                    <a href="#home" className="navbar-brand"><img src="../../../img/img-logo.png" alt/></a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                            aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fa fa-bars"></i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav mr-auto w-100 justify-content-end text-center">
                            <li className="nav-item">
                                <ScrollLink className="nav-link" to="home" smooth={true} duration={700}>Inicio</ScrollLink>
                            </li>
                            <li className="nav-item">
                                <ScrollLink className="nav-link" to="sobre" smooth={true} duration={700}>Sobre</ScrollLink>
                            </li>
                            <li className="nav-item">
                                <ScrollLink className="nav-link" to='Itens' smooth={true} duration={700}>Imóveis</ScrollLink>
                            </li>
                            <li className="nav-item">
                                <ScrollLink className="nav-link" to="parceria" smooth={true} duration={700}>Parcerias</ScrollLink>
                            </li>
                            <li className="nav-item">
                                <ScrollLink className="nav-link" to="avaliacao" smooth={true} duration={700}>Avaliações</ScrollLink>
                            </li>
                            <li className="nav-item">
                                <ScrollLink className="nav-link" to="contato" smooth={true} duration={700}>Contatos</ScrollLink>
                            </li>
                            <li className="nav-item">
                                <a className="btn btn-singin" href="https://wa.link/onasir"><i className="fa fa-whatsapp"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
    <div class="container pt-5 mt-5">
            <div class="row space-100">
                <div class="col-lg-6 col-md-12 col-xs-12">
                    <div class="contents">
                        <h2 class="head-title">
                        Transforme seus sonhos em lares conosco!</h2>
                        <p>Descubra o lar perfeito para sua jornada - confie em nós para realizar seus sonhos imobiliários.</p>
                        <div class="header-button">
                            <a href="#sobre" class="btn btn-border-filled">Saiba mais</a>
                            <a href="https://wa.link/onasir" class="btn btn-border page-scroll">WhatsApp</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-xs-12 p-0">
                    <div class="intro-img">
                        <img src="../../../img/img-banner.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
</header>
    );
}

export default Navbar;