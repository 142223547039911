import React from "react";

import Navbar2 from "./Inicio/navbar2";
import Footer from "./Inicio/footer";
import Fazendapg2 from "./Fazendas/fazenda-pg2";

function Fazenda2() {
    return (<div>
        <Navbar2 />
        <Fazendapg2/>
        <Footer />
    </div>
    )
}

export default Fazenda2;