import React from "react";
import { BrowserRouter, Routes, Route } from  'react-router-dom';

import Site from "./Site/inicio";
import Venda from "./Site/venda";
import Fazenda1 from "./Site/fazendapg1";
import Fazenda2 from "./Site/fazendapg2";
import Sitiopg1 from "./Site/sitiopg1";
import Sitiopg2 from "./Site/sitiopg2";
import Sitiopg3 from "./Site/sitiopg3";

function App() {
  return(
    <BrowserRouter>
    <Routes>
      <Route element={<Site />} path="/" />
      <Route element={<Fazenda1/>} path="/imovel-fazenda-1" />
      <Route element={<Fazenda2/>} path="/imovel-fazenda-2" />
      <Route element={<Venda />} path="/imovel-venda" />
      <Route element={<Sitiopg1 />} path="/imovel-sitio-1" />
      <Route element={<Sitiopg2 />} path="/imovel-sitio-2" />
      <Route element={<Sitiopg3 />} path="/imovel-sitio-3" />
    </Routes>
    </BrowserRouter>  
  );

}

export default App;

// 