import React, { useState } from "react";
import { Link } from "react-router-dom";
import './sitio.css';

const ImageModal20 = ({ onClose }) => {

    return (
        <div className="script-modal over">
            <div className="script-modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <br /><br />
                <div>
                    <img src="../../../img/img-imovel21.jpg" alt="" />
                </div>
            </div>
        </div>
    );
}
const ImageModal21 = ({ onClose }) => {

    return (
        <div className="script-modal over">
            <div className="script-modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <br /><br />
                <div>
                    <img src="../../../img/img-imovel22.jpg" alt="" />

                </div>
            </div>
        </div>

    );
}
const ImageModal22 = ({ onClose }) => {

    return (
        <div className="script-modal over">
            <div className="script-modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <br /><br />
                <div>
                    <img src="../../../img/img-imovel23.jpg" alt="" />
                </div>
            </div>
        </div>

    );
}
const ImageModal23 = ({ onClose }) => {

    return (
        <div className="script-modal over">
            <div className="script-modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <br /><br />
                <div>
                    <img src="../../../img/img-imovel24.jpg" alt="" />
                </div>
            </div>
        </div>

    );
}
const ImageModal24 = ({ onClose }) => {

    return (
        <div className="script-modal over">
            <div className="script-modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <br /><br />
                <div>
                    <img src="../../../img/img-imovel25.jpg" alt="" />
                </div>
            </div>
        </div>

    );
}


function Sitiopage3() {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const [visibleModal20, setVisibleModal20] = useState(false)

    const abrirModal20 = () => {
        setVisibleModal20(true)
    }
    const fecharModal20 = () => {
        setVisibleModal20(false)
    }
    const [visibleModal21, setVisibleModal21] = useState(false)

    const abrirModal21 = () => {
        setVisibleModal21(true)
    }
    const fecharModal21 = () => {
        setVisibleModal21(false)
    }


    const [visibleModal22, setVisibleModal22] = useState(false)

    const abrirModal22 = () => {
        setVisibleModal22(true)
    }
    const fecharModal22 = () => {
        setVisibleModal22(false)
    }


    const [visibleModal23, setVisibleModal23] = useState(false)

    const abrirModal23 = () => {
        setVisibleModal23(true)
    }
    const fecharModal23 = () => {
        setVisibleModal23(false)
    }


    const [visibleModal24, setVisibleModal24] = useState(false)

    const abrirModal24 = () => {
        setVisibleModal24(true)
    }
    const fecharModal24 = () => {
        setVisibleModal24(false)
    }



    return (
        <section className=" ">
            <div className="row box-item desc-imovel">
                {visibleModal20 && (
                    <ImageModal20 onClose={fecharModal20} />
                )}
                <div className="item-img col-md-3">
                    <Link onClick={abrirModal20}><img src="../../../img/img-imovel21.jpg" alt="" /></Link>
                </div>
                <div className="text-imovel col-md-9">
                    <div className="price-imovel">
                        <h4>R$ 2.800.000</h4>
                        <hr />
                    </div>
                    <div className="local-imovel">
                        <p className="font-weight-bold">CABO FRIO</p>
                        <p>Canal , Praia do Siqueira, Cabo Frio</p>
                    </div>
                    <div className="info-imovel">
                        <p>800 m² tot. |
                            800 m² útil |
                            7 banheiros |
                            5 vaga |
                            5 quarto |
                            5 suítes |
                            Breve Lançamento</p>
                        <p>Excelente localização, com 800m² de área total e 360m² de área construída. Sendo composta por 5 quartos, 7 banheiros, sala com <Link to={'https://www.imovelweb.com.br/propriedades/casa-em-cabo-frio-no-canal-2992621318.html'}>...</Link></p>
                    </div>
                    <div className="icon-parceria">
                        <Link to={'https://www.imovelweb.com.br/'}><img src="../../../img/img-parceria.png" alt="" /></Link>
                        <div className="box-btn">
                            <Link className="btn btn-item btn-primary" to={'tel:21998513964'}><i class="fa fa-phone"></i></Link>
                            <Link className="btn btn-item btn-success" to={'https://wa.link/onasir'}><i class="fa fa-whatsapp"></i></Link>
                            <Link className="btn btn-item btn-danger" to={'https://www.imovelweb.com.br/propriedades/casa-em-cabo-frio-no-canal-2992621318.html'}>Ver sobre <i class="fa fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="row box-item desc-imovel">
                {visibleModal21 && (
                    <ImageModal21 onClose={fecharModal21} />
                )}
                <div className="item-img col-md-3">
                    <Link onClick={abrirModal21}><img src="../../../img/img-imovel22.jpg" alt="" /></Link>
                </div>
                <div className="text-imovel col-md-9">
                    <div className="price-imovel">
                        <h4>R$ 420.000</h4>
                        <hr />
                    </div>
                    <div className="local-imovel">
                        <p className="font-weight-bold">GUAPIMIRIM</p>
                        <p>Parada Modelo , Parada Ideal, Guapimirim</p>
                    </div>
                    <div className="info-imovel">
                        <p>1000 m² tot. |
                            1000 m² útil |
                            2 banheiros |
                            40 vaga |
                            3 quarto |
                            Breve Lançamento</p>
                        <p>Linda casa em Guapimirim , Parada Modelo, casa com terreno medindo 1.000m2 toda murada com Área de lazer, piscina, dois andares casa  <Link to={'https://www.imovelweb.com.br/propriedades/casa-mini-chacara-em-guapimirim-parada-modelo-2989883587.html'}>...</Link></p>
                    </div>
                    <div className="icon-parceria">
                        <Link to={'https://www.imovelweb.com.br/'}><img src="../../../img/img-parceria.png" alt="" /></Link>
                        <div className="box-btn">
                            <Link className="btn btn-item btn-primary" to={'tel:21998513964'}><i class="fa fa-phone"></i></Link>
                            <Link className="btn btn-item btn-success" to={'https://wa.link/onasir'}><i class="fa fa-whatsapp"></i></Link>
                            <Link className="btn btn-item btn-danger " to={'https://www.imovelweb.com.br/propriedades/casa-mini-chacara-em-guapimirim-parada-modelo-2989883587.html'}>Ver sobre <i class="fa fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row box-item desc-imovel">
                {visibleModal22 && (
                    <ImageModal22 onClose={fecharModal22} />
                )}
                <div className="item-img col-md-3">
                    <Link onClick={abrirModal22}><img src="../../../img/img-imovel23.jpg" alt="" /></Link>
                </div>
                <div className="text-imovel col-md-9">
                    <div className="price-imovel">
                        <h4>R$ 2.500.000</h4>
                        <hr />
                    </div>
                    <div className="local-imovel">
                        <p className="font-weight-bold">ILHA DO GOVERNADOR</p>
                        <p>Rio de Janeiro , Jardim Carioca, Rio de Janeiro</p>
                    </div>
                    <div className="info-imovel">
                        <p>600 m² tot. |
                            600 m² útil |
                            4 banheiros |
                            8 vaga |
                            4 quarto |
                            3 suítes |
                            Breve Lançamento</p>
                        <p>Casa situada em área nobre da Ilha do Governador - RJ , distribuída em uma entrada com hall de entrada externo 4 quartos sendo uma <Link to={'https://www.imovelweb.com.br/propriedades/casa-mansao-localizada-na-iha-do-governador-2978781546.html'}>...</Link></p>
                    </div>
                    <div className="icon-parceria">
                        <Link to={'https://www.imovelweb.com.br/'}><img src="../../../img/img-parceria.png" alt="" /></Link>
                        <div className="box-btn">
                            <Link className="btn btn-item btn-primary" to={'tel:21998513964'}><i class="fa fa-phone"></i></Link>
                            <Link className="btn btn-item btn-success" to={'https://wa.link/onasir'}><i class="fa fa-whatsapp"></i></Link>
                            <Link className="btn btn-item btn-danger" to={'https://www.imovelweb.com.br/propriedades/casa-mansao-localizada-na-iha-do-governador-2978781546.html'}>Ver sobre <i class="fa fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row box-item desc-imovel">
                {visibleModal23 && (
                    <ImageModal23 onClose={fecharModal23} />
                )}
                <div className="item-img col-md-3">
                    <Link onClick={abrirModal23}><img src="../../../img/img-imovel24.jpg" alt="" /></Link>
                </div>
                <div className="text-imovel col-md-9">
                    <div className="price-imovel">
                        <h4>R$ 280.000</h4>
                        <hr />
                    </div>
                    <div className="local-imovel">
                        <p className="font-weight-bold">GUAPIMIRIM</p>
                        <p>Parada Ideal , Parada Ideal, Guapimirim</p>
                    </div>
                    <div className="info-imovel">
                        <p>2400 m² tot. |
                            2400 m² útil |
                            1 banheiro |
                            8 vaga |
                            2 quarto |
                            Breve Lançamento</p>
                        <p>Guapimirim - Excelente chácara medindo 2.400m2 composta de Casa com 2 quartos, sala, cozinha, banheiro, varanda, e área de serviço <Link to={'https://www.imovelweb.com.br/propriedades/chacara-em-guapimirim-medindo-2.400m2-2979074461.html'}>...</Link></p>
                    </div>
                    <div className="icon-parceria">
                        <Link to={'https://www.imovelweb.com.br/'}><img src="../../../img/img-parceria.png" alt="" /></Link>
                        <div className="box-btn">
                            <Link className="btn btn-item btn-primary" to={'tel:21998513964'}><i class="fa fa-phone"></i></Link>
                            <Link className="btn btn-item btn-success" to={'https://wa.link/onasir'}><i class="fa fa-whatsapp"></i></Link>
                            <Link className="btn btn-item btn-danger" to={'https://www.imovelweb.com.br/propriedades/chacara-em-guapimirim-medindo-2.400m2-2979074461.html'}>Ver sobre <i class="fa fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row box-item desc-imovel">
                {visibleModal24 && (
                    <ImageModal24 onClose={fecharModal24} />
                )}
                <div className="item-img col-md-3">
                    <Link onClick={abrirModal24}><img src="../../../img/img-imovel25.jpg" alt="" /></Link>
                </div>
                <div className="text-imovel col-md-9">
                    <div className="price-imovel">
                        <h4>R$ 600.000</h4>
                        <hr />
                    </div>
                    <div className="local-imovel">
                        <p className="font-weight-bold">GUAPIMIRIM</p>
                        <p>Centro, Guapimirim</p>
                    </div>
                    <div className="info-imovel">
                        <p>7550 m² tot. |
                            7550 m² útil |
                            2 banheiros |
                            8 vaga |
                            4 quarto |
                            1 suíte |
                            Breve Lançamento</p>
                        <p>sítio em Parada Modelo possui uma área total de 7.550 m² e é composto por uma casa duplex. No 1º pavimento, encontramos uma sala <Link to={'https://www.imovelweb.com.br/propriedades/sitio-em-guapimirim-2980977224.html'}>...</Link></p>
                    </div>
                    <div className="icon-parceria">
                        <Link to={'https://www.imovelweb.com.br/'}><img src="../../../img/img-parceria.png" alt="" /></Link>
                        <div className="box-btn">
                            <Link className="btn btn-item btn-primary" to={'tel:21998513964'}><i class="fa fa-phone"></i></Link>
                            <Link className="btn btn-item btn-success" to={'https://wa.link/onasir'}><i class="fa fa-whatsapp"></i></Link>
                            <Link className="btn btn-item btn-danger" to={'https://www.imovelweb.com.br/propriedades/sitio-em-guapimirim-2980977224.html'}>Ver sobre <i class="fa fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="more-pages justify-content-center d-flex">
                <ul className="row links-more-pages">
                    <Link to={'../../imovel-sitio-1'}><li onClick={scrollToTop}>1</li></Link>
                    <Link to={'../../imovel-sitio-2'}><li onClick={scrollToTop}>2</li></Link>
                    <Link to={'../../imovel-sitio-3'}><li onClick={scrollToTop}>3</li></Link>
                </ul>
            </div>
        </section>
    );
}

export default Sitiopage3;